.slider__item {
  width: 100%;
  height: 650px;
  max-width: 100vw; /* Ensures it doesn't exceed the viewport width */
  overflow: hidden; /* Ensures no content spills out */
  display: flex; /* Use flexbox to center the image */
  align-items: center; /* Vertically center the image */
  justify-content: center; /* Horizontally center the image */
}

.image {
  width: 100%;
  height: 100%;
  /* object-fit: contain; Ensures the full image is visible without being cut */
  object-position: center; /* Centers the image within the container */
}

.hero__slider {
  overflow: hidden;
}

.reserve__btn {
  background: #fff !important;
  color: #000d6b !important;
  border: none;
  outline: none;
}

.reserve__btn a {
  text-decoration: none;
  font-weight: 600;
  color: #000d6b;
}

.reserve__btn:hover {
  background: #000d6b;
  color: #fff;
}

@media only screen and (max-width: 992px) {
  .slider__item {
    height: 530px;
    max-width: 100vw; /* Ensures it doesn't exceed the viewport width */
  }

  /* Additional adjustments for smaller screens */
}

@media only screen and (max-width: 768px) {
  .slider__item {
    height: 300px;
    max-width: 100vw; /* Ensures it doesn't exceed the viewport width */
  }

  /* Additional adjustments for smaller devices */
}
