/* AdminPanel.css */

/* Container for the entire Admin Panel */
.admin-container {
    padding: 20px;
  }
  
  /* Flex container for layout */
  .admin-flex-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  /* Add Car Form Section */
  .admin-add-form {
    margin-bottom: 20px;
  }
  
  /* Cars List Section */
  .admin-cars-list {
    margin-bottom: 20px;
  }
  
  /* Upload Image Section */
  .admin-upload-section {
    margin-bottom: 20px;
  }
  
  /* Heading styles */
  .admin-heading {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  /* Table container styles */
  .admin-table-container {
    margin-top: 10px;
    overflow: auto;
  }
  
  /* Table header styles */
  .admin-table-head {
    background-color: #f0f0f0;
  }
  
  /* Table header cell styles */
  .admin-table-header {
    font-weight: bold;
  }
  
  /* Table row styles */
  .admin-table-row {
    &:nth-of-type(even) {
      background-color: #f9f9f9;
    }
  }
  
  /* Table cell styles */
  .admin-table-cell {
    padding: 10px;
    text-align: center;
  }
  
  /* Delete button styles */
  .admin-delete-button {
    background-color: #e57373;
    color: white;
  }
  
  /* File input styles */
  .admin-file-input {
    margin-bottom: 30px;
  }
  
  /* Image preview styles */
  .admin-image-preview {
    max-width: 100px;
    max-height: 100px;
    object-fit: cover;
    border: 1px solid #ddd;
    border-radius: 4px;
  }

  /* Container styles */
.image-upload-form {
    max-width: 100%;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
    text-align: center;
  }
  
  /* File input styles */
  .image-upload-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 16px;
  }
  
  /* Button styles */
  .image-upload-button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .image-upload-button:hover {
    background-color: #0056b3;
  }
  
  /* Responsive adjustments */
  @media (max-width: 480px) {
    .image-upload-form {
      padding: 15px;
    }
  
    .image-upload-input {
      padding: 8px;
      font-size: 14px;
    }
  
    .image-upload-button {
      padding: 8px;
      font-size: 14px;
    }
  }
  
  