/* Container styles */
.add-car-form {
    max-width: 100%;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
  }
  
  /* Input field styles */
  .add-car-form .form-input,
  .add-car-form .form-file-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 16px;
  }
  
  /* Placeholder styles */
  .add-car-form .form-input::placeholder {
    color: #888;
  }
  
  /* Button styles */
  .add-car-form .form-submit-button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin: 2% 0;
  }
  
  .add-car-form .form-submit-button:hover {
    background-color: #0056b3;
  }
  
  /* Focus state */
  .add-car-form .form-input:focus {
    border-color: #007bff;
    outline: none;
  }
  
  /* File input styling */
  .add-car-form .form-file-input {
    padding: 3px;
    font-size: 14px;
  }
  
  .add-car-form select {
    background-color: #fff;
  }
  
  /* Responsive adjustments */
  @media (max-width: 480px) {
    .add-car-form {
      padding: 15px;
    }
  
    .add-car-form .form-input,
    .add-car-form .form-file-input {
      padding: 8px;
      font-size: 14px;
    }
  
    .add-car-form .form-submit-button {
      padding: 8px;
      font-size: 14px;
    }
  }
  